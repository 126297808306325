
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { VueCookieNext } from 'vue-cookie-next';
import moment from 'moment';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'batch motor driving report',
  components: {
    Datatable,
  },

  data() {
    return {
      pdfHeader: [] as any,
      tranches: [] as any,
      start_date: '',
      end_date: '',
      TranceLabel: '',
      TpartnerLabel: '',
      TinstituteLabel: '',
      TbatchLabel: '',
      LicenseLabel: '',
      TranceData: '',
      TpartnerData: '',
      TinstituteData: '',
      TbatchData: '',
      LicenseData: '',
      tabLoad: false,
      licenseProvided: [
        {
          id: 1,
          name: 'Yes',
        },
        {
          id: 2,
          name: 'No',
        },
      ],
      tableData: [] as any,
      tableHeader: [
        {
          name: 'SL',
          key: 'sl',
          selected: true,
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
          selected: true,
        },
        {
          name: 'Training Partner',
          key: 'association',
          sortable: true,
          selected: true,
        },
        {
          name: 'Institute',
          key: 'institute',
          sortable: true,
          selected: true,
        },
        {
          name: 'Batch',
          key: 'batch',
          sortable: true,
          selected: true,
        },
        {
          name: 'Start Date',
          key: 'start_date',
          width: '120px',
          sortable: true,
          selected: true,
        },
        {
          name: 'Enrolled',
          key: 'enrolled',
          sortable: true,
          selected: true,
        },
        {
          name: 'Enrolled(Female)',
          key: 'enrolled_female',
          sortable: true,
          selected: true,
        },
        {
          name: 'Date of Assessment',
          key: 'assessment_date',
          width: '120px',
          sortable: true,
          selected: true,
        },
        {
          name: 'Assessed By BRTA',
          key: 't_assessed',
          sortable: true,
          selected: true,
        },
        {
          name: 'Assessed By BRTA(Female)',
          key: 'f_assessed',
          sortable: true,
          selected: true,
        },
        {
          name: 'Passed',
          key: 'passed',
          sortable: true,
          selected: true,
        },

        {
          name: 'Passed(Female)',
          key: 'passed_female',
          sortable: true,
          selected: true,
        },
        {
          name: 'License Received',
          key: 'license',
          sortable: true,
          selected: true,
        },
        {
          name: 'License Received(Female)',
          key: 'license_female',
          sortable: true,
          selected: true,
        },
      ] as any,
      moment: '' as any,
      instituteList: [] as any,
      st: [] as any,
      batchList: [] as any,
      loading: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      entityInfos: [] as any,
      headerSelect: false,
      load: true,
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranche();
    await this.associationList();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch', this.TbatchData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);


      let data = `${this.VUE_APP_API_URL}/api/report/motordrivingprint`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async exportTrainee() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch', this.TbatchData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/motordrivingExcel`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'MotorDrivingBatch.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async downloadPdf() {
      let formData = new FormData();
      this.pdfHeader = [] as any;
      for (let i = 0; i < this.st.length; i++) {
        this.pdfHeader.push({
          header: this.st[i]?.name,
          key: this.st[i]?.key,
        });
      }
      formData.set('tableHeader', JSON.stringify(this.pdfHeader));
      formData.set('entity', this.TpartnerData);
      formData.set('institute', this.TinstituteData);
      formData.set('batch', this.TbatchData);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);

      // console.log(JSON.stringify(this.st));

      await ApiService.post('report/motordrivingPdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    trancheLabelChange(id) {
      let selectedtranche = this.tranches.filter((data) => data.id == id);

      this.TranceLabel = selectedtranche[0].label;
    },
    batchLabelChange(id) {
      let selectedbatch = this.batchList.filter((data) => data.id == id);

      this.TbatchLabel = selectedbatch[0].batch_sequence_number;
    },
    // licenseLabelChange(id) {
    //   let selectedlicense = this.licenseProvided.filter((data) => data.id == id);

    //   this.LicenseLabel = selectedlicense[0].name;
    // },

    resetData() {
      this.pdfHeader = [] as any;
      this.TpartnerLabel = '';
      this.TranceLabel = '';
      this.TinstituteLabel = '';
      this.TbatchLabel = '';
      this.LicenseLabel = '';
      this.start_date = '';
      this.end_date = '';
      this.TranceData = '';
      this.TpartnerData = '';
      this.TinstituteData = '';
      this.TbatchData = '';
      this.LicenseData = '';
      this.headerSelect = false;
      this.showall = false;
      this.showTableData = false;
      this.componentTableKey += 1;
    },

    async getTranche() {
      this.tabLoad = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.tabLoad = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.tabLoad = false;
        });
    },
    async associationList() {
      this.tabLoad = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        'motor-driving/association/list?entity_id=' + entity_id
      )
        .then((response) => {
          this.entityInfos = response.data.data;
          this.tabLoad = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.tabLoad = false;
        });
    },
    async getInstitute(id) {
      this.tabLoad = true;
      let selectedEntity = this.entityInfos.filter((data) => data.id == id);

      this.TpartnerLabel = selectedEntity[0].entity_short_name;
      await ApiService.get(
        'institute/filterInstitute?entity=' + this.TpartnerData
      )
        .then((response) => {
          this.instituteList = response.data.data;
          this.tabLoad = false;
        })
        .catch((response) => {
          console.log(response);
          this.tabLoad = false;
        });
    },
    async getBatches(id) {
      this.tabLoad = true;
      let selectedInstitute = this.instituteList.filter(
        (data) => data.id == id
      );
      this.TinstituteLabel = selectedInstitute[0].short_name;
      await ApiService.get(
        'motor-driving/batch/list?institute=' +
        this.TinstituteData +
        '&entity_id=' +
        this.TpartnerData +
        '&tranche_id=' +
        this.TranceData
      )
        .then((response) => {
          this.batchList = response.data.data;
          this.tabLoad = false;
        })
        .catch((response) => {
          console.log(response);
          this.tabLoad = false;
        });
    },

    async getData() {
      if (this.TpartnerData || this.TranceData) {
        this.tabLoad = true;
        await ApiService.get(
          'report/motor-driving-batch?tranche=' +
          this.TranceData +
          '&entity=' +
          this.TpartnerData +
          '&institute=' +
          this.TinstituteData +
          '&batch=' +
          this.TbatchData +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date
        )
          .then((response) => {
            this.headerSelect = true;
            this.selectedTableHeader();
            this.tableData = response.data.data;
            this.componentTableKey += 1;
            this.tabLoad = false;
          })
          .then((response) => {
            console.log(response);
            this.tabLoad = false;
          });
      } else {
        Swal.fire({
          title: 'Error Found',
          text: 'Tranche or Training Partner Required',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    selectedTableHeader() {
      this.st = Array();
      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
    },
  },

  computed: {},
  setup() { },
});
